import React from 'react';
import { Link } from 'react-router-dom';
// import { Carousel } from 'react-bootstrap';

function HowBanner() {
    
    return (
        <>

            
		<div className="banner">
            <img
                src='../../assets/images/How.jpg'
                alt="Responsive Banner"
                title="Learn How ConnectMyVA Works - Virtual Assistant Services"
                className="bannerImage"
            />
        </div>
            

        </>
    )
}

export default HowBanner
