import React from 'react';
// import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function LinkedinBanner() {
    
    return (
        <>

        <div className="banner">
            <img
                src='../../assets/images/Linkedin.jpg'
                 alt="LinkedIn Lead Generation and Profile Services - ConnectMyVA"
                    title="Professional LinkedIn Virtual Assistant Services - ConnectMyVA"
                className="bannerImage"
            />			
        </div>
            

        </>
    )
}

export default LinkedinBanner
