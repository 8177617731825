import React from 'react';
import { Link } from 'react-router-dom';
// import { Carousel } from 'react-bootstrap';

function FAQBanner() {
    
    return (
        <>
		<div className="banner">
            <img
                src='../../assets/images/FAQ.jpg'
                alt="Responsive Banner"
                className="bannerImage"
            />
        </div>
        </>
    )
}

export default FAQBanner
