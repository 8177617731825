import React from 'react';
import { Link } from 'react-router-dom';
// import { Carousel } from 'react-bootstrap';

function ProcessBanner() {
    
    return (
        <>

		<div className="banner">
            <img
                src='../../assets/images/process.jpg'
                alt="ConnectMyVA Virtual Assistant Process Banner"
                title="Our Simple Process - ConnectMyVA Virtual Assistant Services"
                className="bannerImage"
            />
        </div>
            

        </>
    )
}

export default ProcessBanner
